export const sitemapAlternatives = {
  label: "sitemap language alternatives",
  name: "langAlternative",
  widget: "list",
  collapsed: true,
  required: false,
  fields: [
    {
      label: "language",
      name: "language",
      widget: "select",
      options: ["en", "es"],
      required: false
    },
    {
      label: "page url",
      name: "url",
      widget: "string",
      required: false
    }
  ]
}
